<template>
  <div id="PersonalCenter">
    <van-nav-bar :fixed="true" :title="$t('My.PersonalInformation.Page.title')" left-arrow placeholder
      @click-left="onClickLeft" />
    <div class="content">
      <div class="content-item" :class="{ state: NavIndex == index }" v-for="(item, index) in Label" :key="index"
        @click="MousedownEvent(index, item.url)">
        <div>
          <van-image width="1rem" height="1rem" round :src="item.img" />
          <span>{{ $t(item.text) }}</span>
        </div>
        <div>
          <van-image v-if="item.isAvatar" width="2.6rem" height="2.5rem" round :src="$img(userInfo.userimg)" />
          <span v-if="index == 1 || index == 2">{{ item.span }}</span>
          <van-icon name="arrow" color="#232f3e" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  Toast,
} from "vant";
import { mapGetters } from "vuex";

export default {
  name: "PersonalCenter",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Toast.name]: Toast,
  },
  data() {
    return {
      //   userInfo: {},
      Label: [
        {
          id: "1",
          // img:require('@/assets/Img/myImg/MyOne.png'),
          img: require("@/assets/icon/myInfo/avatar.png"),
          image: "https://img.yzcdn.cn/vant/cat.jpeg",
          url: "Portrait",
          // url:"",
          text: `My.PersonalInformation.Page.Avatar`,
          isAvatar: true
        },
        {
          id: "2",
          // img:require('@/assets/Img/myImg/MyTwo.png'),
          img: require("@/assets/icon/myInfo/edt.png"),
          span: "Aabb2021",
          url: "Name",
          text: `My.PersonalInformation.Page.Nickname`,
        },
        {
          id: "3",
          // img:require('@/assets/Img/myImg/MyThree.png'),
          img: require("@/assets/icon/myInfo/phone.png"),
          span: "83131587204",
          url: "",
          text: `My.PersonalInformation.Page.PhoneNumber`,
        },
        // {
        //   id: "4",
        //   // img:require('@/assets/Img/myImg/MyFour.png'),
        //   img: require("@/assets/icon/myInfo/bank.png"),
        //   url: "add-bank-card",
        //   text: `My.PersonalInformation.Page.BankAccounts`,
        // },
        {
          id: "5",
          // img:require('@/assets/Img/myImg/MyFive.png'),
          img: require("@/assets/icon/myInfo/pwd.png"),
          url: "Password",
          text: `My.PersonalInformation.Page.Password`,
        },
        // {
        //     id:"6",
        //     img:require('@/assets/Img/myImg/MyFive.png'),
        //     url:"PaymentCode",
        //     text:`My.PersonalInformation.Page.TransactionPassword`,
        // },
        // {
        //     id:"7",
        //     img:require('@/assets/Img/myImg/MyFive.png'),
        //     url:"Site",
        //     text:`My.PersonalInformation.Page.ReceivingAddress`,
        // },
      ],
      NavIndex: -1, // 控制选中状态的
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1);
    },
    // 标签鼠标按下
    MousedownEvent(num, url) {
      let _this = this;
      this.NavIndex = num;
      setTimeout(function () {
        _this.NavIndex = -1;
      }, 150);
      if (url != "") {
        this.$router.push(url);
      }
    },
    // 获取用户信息
    getUserInfo() {
      this.Label[0].image = this.userInfo.userimg;
      this.Label[1].span = this.userInfo.username;
      this.Label[2].span = this.userInfo.userphone;
    },
  },
  created() {
    this.$store.dispatch('getUserInfo');
    this.getUserInfo();
    // if(this.module.PersonalEvnet()[1].data.player.icon!=""){
    //     this.Label[0].image = this.module.PersonalEvnet()[1].data.player.icon;
    // }else{
    //     this.Label[0].image = require('@/assets/Img/myImg/head.png')
    // }
    // this.Label[1].span = this.module.PersonalEvnet()[1].data.player.nickname
    // this.Label[2].span = this.module.PersonalEvnet()[0].data.info.phone
  },
};
</script>

<style lang="less">
#PersonalCenter {
  width: 100%;
  height: 100%;
  .van-nav-bar__content {
    background-color: var(--navBg);
    height: calc(50rem / 16);
  }
  .van-nav-bar__title{
    color: #fff;
  }
  .van-nav-bar .van-icon{
    color: #fff;
  }
  .content {
    width: calc(100% - 2rem);
    padding: 0.125rem 1rem;

    .state {
      background-color: #ade1f5;
    }

    .content-item {
      height: 3.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;

      >div {
        display: flex;
        flex-direction: row;
        align-items: center;

        >span {
          font-size: 1rem;
        }

        .van-image {
          margin-right: 0.3rem;
        }
      }

      >div:nth-child(2) {
        >span {
          font-size: 0.75rem;
          color: #999;
        }

        .van-icon {
          margin-left: 0.75rem;
          color: #ddd;
        }
      }
    }
  }
}
</style>